<template>
    <div class="html-block">
        <RichTextElements :html="content" />
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
const props = defineProps({
    html: {
        type: String,
        default: '',
    },
});
// this returns the content direction (ltr/rtl) in case that needs to be accounted for in arabic content.
// const direction = inject('direction');

function revealOverlay(contentWrapperId) {
    document.querySelector(contentWrapperId).classList.add('active');
    const closeButton =
        '<div class="overlay-close"><svg data-v-459a476e="" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" class=""><circle cx="18" cy="18" r="17.5" stroke="currentColor"></circle><path stroke="currentColor" d="M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93"></path></svg></div>';
    document.querySelector(contentWrapperId).firstElementChild.innerHTML += closeButton;
    closeOverlay();
}
function nextSlide(el) {
    const targetParent = el.parentElement.parentElement;
    const target = targetParent.querySelectorAll('.item.active');

    // the collection you're looking in
    const nextSlide = target[0].nextElementSibling;

    if (nextSlide !== null) {
        nextSlide.classList.add('active');
        target[0].classList.remove('active');
    }
}
function prevSlide(el) {
    // the element you're looking for
    const targetParent = el.parentElement.parentElement;
    const target = targetParent.querySelectorAll('.item.active');

    // the collection you're looking in
    const prevSlide = target[0].previousElementSibling;

    if (prevSlide !== null) {
        target[0].classList.remove('active');
        prevSlide.classList.add('active');
    }
}

function moreLessShow(e) {
    const triggerLabel = e.target.innerHTML;
    const moreLabel = e.target.dataset.moreLabel;
    const lessLabel = e.target.dataset.lessLabel;

    if (triggerLabel === moreLabel) {
        e.target.innerHTML = lessLabel;
    } else {
        e.target.innerHTML = moreLabel;
    }
    e.target.previousElementSibling.classList.toggle('hidden');
}

const content = computed(() => {
    if (props.html.length) {
        return props.html.replaceAll('<p>&nbsp;</p>', '');
    }
});

function makeYouTubeResponsive() {
    const iframes = document.getElementsByTagName('iframe');
    Array.from(iframes).forEach(function (element) {
        if (element.src.includes('youtube.com')) {
            element.classList.add('youtube-video');
        }
    });
}

function diversitySelect() {
    if (document.querySelector('#demographicDEISwap')) {
        const selectElement = document.querySelector('#demographicDEISwap');
        selectElement.addEventListener('change', (event) => {
            if (event.target.value === 'all') {
                document
                    .getElementById('DEIUSImageSwap')
                    .setAttribute('src', 'https://ceipimg.s3.amazonaws.com/static/dei/DEI_2023-All.png');
            } else if (event.target.value === 'leadership') {
                document
                    .getElementById('DEIUSImageSwap')
                    .setAttribute('src', 'https://ceipimg.s3.amazonaws.com/static/dei/DEI_2023-Leadership.png');
            } else if (event.target.value === 'operational') {
                document
                    .getElementById('DEIUSImageSwap')
                    .setAttribute('src', 'https://ceipimg.s3.amazonaws.com/static/dei/DEI_2023-Operational.png');
            } else if (event.target.value === 'support') {
                document
                    .getElementById('DEIUSImageSwap')
                    .setAttribute('src', 'https://ceipimg.s3.amazonaws.com/static/dei/DEI_2023_Research Support.png');
            } else if (event.target.value === 'research') {
                document
                    .getElementById('DEIUSImageSwap')
                    .setAttribute('src', 'https://ceipimg.s3.amazonaws.com/static/dei/DEI_2023-Senior Research.png');
            }
        });
    }
}

function IndiaStateImageSwap2018() {
    if (document.querySelector('#IndiaStateImageSwap-doc77406')) {
        const selectElement = document.querySelector('#IndiaStateDropdown');
        selectElement.addEventListener('change', (event) => {
            if (event.target.value === 'AndhraPradesh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5c+Andhra+Pradesh.jpg',
                    );
            } else if (event.target.value === 'ArunachalPradesh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Arunachal+Pradesh.jpg',
                    );
            } else if (event.target.value === 'Assam') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Assam.jpg',
                    );
            } else if (event.target.value === 'Bihar') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5b+Bihar.jpg',
                    );
            } else if (event.target.value === 'Chhattisgarh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Chhattisgarh.jpg',
                    );
            } else if (event.target.value === 'Delhi') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5b+Delhi.jpg',
                    );
            } else if (event.target.value === 'Goa') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Goa.jpg',
                    );
            } else if (event.target.value === 'Gujarat') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Gujarat.jpg',
                    );
            } else if (event.target.value === 'Haryana') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5c+Haryana.jpg',
                    );
            } else if (event.target.value === 'HimachalPradesh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Himachal+Pradesh.jpg',
                    );
            } else if (event.target.value === 'JammuKashmir') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5c+Jammu+%26+Kashmir.jpg',
                    );
            } else if (event.target.value === 'Jharkhand') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5d+Jharkhand.jpg',
                    );
            } else if (event.target.value === 'Karnataka') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Karnataka.jpg',
                    );
            } else if (event.target.value === 'Kerala') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Kerala.jpg',
                    );
            } else if (event.target.value === 'MadhyaPradesh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Madhya+Pradesh.jpg',
                    );
            } else if (event.target.value === 'Maharashtra') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5c+Maharashtra.jpg',
                    );
            } else if (event.target.value === 'Manipur') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Manipur.jpg',
                    );
            } else if (event.target.value === 'Meghalaya') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Meghalaya.jpg',
                    );
            } else if (event.target.value === 'Mizoram') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Mizoram.jpg',
                    );
            } else if (event.target.value === 'Nagaland') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Nagaland.jpg',
                    );
            } else if (event.target.value === 'Odisha') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Odisha.jpg',
                    );
            } else if (event.target.value === 'Puducherry') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Puducherry.jpg',
                    );
            } else if (event.target.value === 'Punjab') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Punjab.jpg',
                    );
            } else if (event.target.value === 'Rajasthan') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Rajasthan.jpg',
                    );
            } else if (event.target.value === 'Sikkim') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5d+Sikkim.jpg',
                    );
            } else if (event.target.value === 'TamilNadu') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5b+Tamil+Nadu.jpg',
                    );
            } else if (event.target.value === 'Tripura') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Tripura.jpg',
                    );
            } else if (event.target.value === 'Uttarakhand') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5a+Uttarakhand.jpg',
                    );
            } else if (event.target.value === 'UttarPradesh') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5b+Uttar+Pradesh.jpg',
                    );
            } else if (event.target.value === 'WestBengal') {
                document
                    .getElementById('IndiaStateImageSwap')
                    .setAttribute(
                        'src',
                        'https://s3.amazonaws.com/ceipimg/static/201810-Vaishnav/201810-Vaishnav-Figure+5b+West+Bengal.jpg',
                    );
            }
        });
    }
}

function EUMapsImageSwap2020() {
    if (document.querySelector('#EUMaps-doc81658')) {
        const selectElement = document.querySelector('#EUMapDropdown');
        selectElement.addEventListener('change', (event) => {
            if (event.target.value === 'Visits') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/202004-EU-Map_1-Visits.jpg',
                    );
            } else if (event.target.value === 'ReferencesHighRep') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_2_HRVP_Declaration.jpg',
                    );
            } else if (event.target.value === 'ReferencesCouncil') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_3_Council_Conclusions.jpg',
                    );
            } else if (event.target.value === 'Sanctions') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_4_Sanctions.jpg',
                    );
            } else if (event.target.value === 'Trade') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_5_Trade_Agreements.jpg',
                    );
            } else if (event.target.value === 'Assistance') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_6_EU_Assistance.jpg',
                    );
            } else if (event.target.value === 'Delegations') {
                document
                    .getElementById('EUMapImageSwap')
                    .setAttribute(
                        'src',
                        'https://images.carnegieendowment.org/images/article_images/Map_7_EU_Delegations.jpg',
                    );
            }
        });
    }
}

function MoonNorthKorea2019Charts(e, srcAtt) {
    const SiblingsNotText = document.querySelectorAll('#MoonNorthKorea-doc77946 li');
    console.log(SiblingsNotText);
    SiblingsNotText.forEach((el) => {
        if (el.id !== e.target.id && el.classList.contains('active')) {
            el.classList.remove('active');
        }
    });
    e.target.parentElement.classList.add('active');
    document.getElementById('MoonNorthKoreaGraphSwap').setAttribute('src', srcAtt);
}

function MoonNorthKorea2019() {
    if (document.querySelector('#MoonNorthKorea-doc77946')) {
        document.querySelector('#MoonNorthKoreaGraphRohTaewoo').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/01_Tae-woo.png');
        });
        document.querySelector('#MoonNorthKoreaGraphKimYoungSam').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/01_Young-sam.png');
        });
        document.querySelector('#MoonNorthKoreaGraphKimYoungSam').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/01_Young-sam.png');
        });
        document.querySelector('#MoonNorthKoreaGraphKimDaeJung').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/03_Dae-jung.png');
        });
        document.querySelector('#MoonNorthKoreaGraphRohMooHyun').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/04_Moo-hyun.png');
        });
        document.querySelector('#MoonNorthKoreaGraphLee').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/05_Myung-bak.png');
        });
        document.querySelector('#MoonNorthKoreaGraphPark').addEventListener('click', (e) => {
            e.preventDefault();
            MoonNorthKorea2019Charts(e, 'https://s3.amazonaws.com/ceipimg/static/201901-Lee/06_Geun-hye.png');
        });
    }
}

function closeOverlay() {
    const overlayClose = document.querySelectorAll('.overlay-close');
    overlayClose.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('.overlay-content.active').classList.remove('active');
        }),
    );
}
function quadSpaceBilats(e) {
    function highlight(id, activeCount) {
        if (activeCount > 0) {
            const elList = document.querySelectorAll('#accords .bilats');
            elList.forEach((el) => {
                if (el.classList.contains('active') && !el.classList.contains(id)) {
                    el.classList.remove('active');
                }
            });
        } else if (activeCount < 2) {
            const elList = document.querySelectorAll('#accords .bilats');
            elList.forEach((el) => {
                if (el.classList.contains(id)) {
                    el.classList.add('active');
                }
            });
        } else if (activeCount >= 2) {
            alert(
                'Please select only two countries to display bilateral agreements and partnerships. To change between pairings, please deselect one country by clicking on the country name before selecting another.',
            );
        }
    }
    function removeHighlight(id, activeCount) {
        if (activeCount > 0) {
            let stillActive = '';
            const elListActive = document.querySelectorAll('#quad-buttons .svg-click');
            elListActive.forEach((el) => {
                if (el.classList.contains('active')) {
                    stillActive = el.id;
                }
            });
            const elList = document.querySelectorAll('#accords .bilats');
            elList.forEach((el) => {
                if (el.classList.contains(stillActive)) {
                    el.classList.add('active');
                } else {
                    el.classList.remove('active');
                }
            });
        } else {
            const elList = document.querySelectorAll('#accords .bilats');
            elList.forEach((el) => {
                if (el.classList.contains(id)) {
                    el.classList.remove('active');
                }
            });
        }
    }
    const quadSpaceBilatsSVG = document.getElementById('quadSpaceBilats');
    if (quadSpaceBilatsSVG) {
        e.preventDefault();
        const activeCount = document.querySelectorAll('.svg-click.active').length;
        if (e.target.tagName === 'path') {
            const activeThing = e.target.closest('.svg-click');
            if (activeThing.classList.contains('active')) {
                activeThing.classList.remove('active');
                removeHighlight(activeThing.id, activeCount);
            } else if (activeCount < 2) {
                highlight(activeThing.id, activeCount);
                activeThing.classList.add('active');
            } else if (activeCount >= 2) {
                alert(
                    'Please select only two countries to display bilateral agreements and partnerships. To change between pairings, please deselect one country by clicking on the country name before selecting another.',
                );
            }
        }
    }
}

function KoreanUnification2019() {
    const IDmouseover = function (Pattern, Number1, Number2, Number3) {
        const collapse = 'Collapse' + Pattern;
        const conflict = 'Conflict' + Pattern;
        const peaceful = 'Peaceful' + Pattern;
        const text1 = 'text-description' + Number1;
        const text2 = 'text-description' + Number2;
        const text3 = 'text-description' + Number3;
        document.getElementById('afb9d905-388a-45f7-b141-8e88920e5358').classList.add('rollover');
        document.getElementById(collapse).classList.add('active');
        document.getElementById(conflict).classList.add('active');
        document.getElementById(text3).classList.add('active');
        document.getElementById(text2).classList.add('active');
        if (Number1 !== 'skip') {
            document.getElementById(peaceful).classList.add('active');
            document.getElementById(text1).classList.add('active');
        }
    };
    const IDmouseout = function (Pattern, Number1, Number2, Number3) {
        const collapse = 'Collapse' + Pattern;
        const conflict = 'Conflict' + Pattern;
        const peaceful = 'Peaceful' + Pattern;
        const text1 = 'text-description' + Number1;
        const text2 = 'text-description' + Number2;
        const text3 = 'text-description' + Number3;
        document.getElementById('afb9d905-388a-45f7-b141-8e88920e5358').classList.remove('rollover');
        document.getElementById(collapse).classList.remove('active');
        document.getElementById(conflict).classList.remove('active');
        document.getElementById(text3).classList.remove('active');
        document.getElementById(text2).classList.remove('active');
        if (Number1 !== 'skip') {
            document.getElementById(peaceful).classList.remove('active');
            document.getElementById(text1).classList.remove('active');
        }
    };
    const KoreanUnification2019SVG = document.getElementById('KoreanUnification-doc78737');
    if (KoreanUnification2019SVG) {
        document.getElementById('CollapseNegotiating').addEventListener('mouseout', (event) => {
            IDmouseover('Negotiating', '-3', '-15', '-20');
        });
        document.getElementById('CollapseNegotiating').addEventListener('mouseout', (event) => {
            IDmouseout('Negotiating', '-3', '-15', '-20');
        });
        document.getElementById('ConflictNegotiating').addEventListener('mouseover', (event) => {
            IDmouseover('Negotiating', '-3', '-15', '-20');
        });
        document.getElementById('ConflictNegotiating').addEventListener('mouseout', (event) => {
            IDmouseout('Negotiating', '-3', '-15', '-20');
        });
        document.getElementById('PeacefulNegotiating').addEventListener('mouseover', (event) => {
            IDmouseover('Negotiating', '-3', '-15', '-20');
        });
        document.getElementById('PeacefulNegotiating').addEventListener('mouseout', (event) => {
            IDmouseout('Negotiating', '-3', '-15', '-20');
        });

        document.getElementById('CollapseDefensiveOperations').addEventListener('mouseover', (event) => {
            IDmouseover('DefensiveOperations', '-7', '-222', '-8');
        });
        document.getElementById('CollapseDefensiveOperations').addEventListener('mouseout', (event) => {
            IDmouseout('DefensiveOperations', '-7', '-222', '-8');
        });
        document.getElementById('ConflictDefensiveOperations').addEventListener('mouseover', (event) => {
            IDmouseover('DefensiveOperations', '-7', '-222', '-8');
        });
        document.getElementById('ConflictDefensiveOperations').addEventListener('mouseout', (event) => {
            IDmouseout('DefensiveOperations', '-7', '-222', '-8');
        });
        document.getElementById('PeacefulDefensiveOperations').addEventListener('mouseover', (event) => {
            IDmouseover('DefensiveOperations', '-7', '-222', '-8');
        });
        document.getElementById('PeacefulDefensiveOperations').addEventListener('mouseout', (event) => {
            IDmouseout('DefensiveOperations', '-7', '-222', '-8');
        });

        document.getElementById('CollapseCivilSecurity').addEventListener('mouseover', (event) => {
            IDmouseover('CivilSecurity', '-4', '-16', '-11');
        });
        document.getElementById('CollapseCivilSecurity').addEventListener('mouseout', (event) => {
            IDmouseout('CivilSecurity', '-4', '-16', '-11');
        });
        document.getElementById('ConflictCivilSecurity').addEventListener('mouseover', (event) => {
            IDmouseover('CivilSecurity', '-4', '-16', '-11');
        });
        document.getElementById('ConflictCivilSecurity').addEventListener('mouseout', (event) => {
            IDmouseout('CivilSecurity', '-4', '-16', '-11');
        });
        document.getElementById('PeacefulCivilSecurity').addEventListener('mouseover', (event) => {
            IDmouseover('CivilSecurity', '-4', '-16', '-11');
        });
        document.getElementById('PeacefulCivilSecurity').addEventListener('mouseout', (event) => {
            IDmouseout('CivilSecurity', '-4', '-16', '-11');
        });

        document.getElementById('CollapseRuleofLaw').addEventListener('mouseover', (event) => {
            IDmouseover('RuleofLaw', '-2', '-17', '-12');
        });
        document.getElementById('CollapseRuleofLaw').addEventListener('mouseout', (event) => {
            IDmouseout('RuleofLaw', '-2', '-17', '-12');
        });
        document.getElementById('ConflictRuleofLaw').addEventListener('mouseover', (event) => {
            IDmouseover('RuleofLaw', '-2', '-17', '-12');
        });
        document.getElementById('ConflictRuleofLaw').addEventListener('mouseout', (event) => {
            IDmouseout('RuleofLaw', '-2', '-17', '-12');
        });
        document.getElementById('PeacefulRuleofLaw').addEventListener('mouseover', (event) => {
            IDmouseover('RuleofLaw', '-2', '-17', '-12');
        });
        document.getElementById('PeacefulRuleofLaw').addEventListener('mouseout', (event) => {
            IDmouseout('RuleofLaw', '-2', '-17', '-12');
        });

        document.getElementById('CollapseSupportingGovernance').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingGovernance', '-5', '-18', '-13');
        });
        document.getElementById('CollapseSupportingGovernance').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingGovernance', '-5', '-18', '-13');
        });
        document.getElementById('ConflictSupportingGovernance').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingGovernance', '-5', '-18', '-13');
        });
        document.getElementById('ConflictSupportingGovernance').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingGovernance', '-5', '-18', '-13');
        });
        document.getElementById('PeacefulSupportingGovernance').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingGovernance', '-5', '-18', '-13');
        });
        document.getElementById('PeacefulSupportingGovernance').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingGovernance', '-5', '-18', '-13');
        });

        document.getElementById('CollapseSupportingDevelopment').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingDevelopment', '-6', '-19', '-14');
        });
        document.getElementById('CollapseSupportingDevelopment').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingDevelopment', '-6', '-19', '-14');
        });
        document.getElementById('ConflictSupportingDevelopment').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingDevelopment', '-6', '-19', '-14');
        });
        document.getElementById('ConflictSupportingDevelopment').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingDevelopment', '-6', '-19', '-14');
        });
        document.getElementById('PeacefulSupportingDevelopment').addEventListener('mouseover', (event) => {
            IDmouseover('SupportingDevelopment', '-6', '-19', '-14');
        });
        document.getElementById('PeacefulSupportingDevelopment').addEventListener('mouseout', (event) => {
            IDmouseout('SupportingDevelopment', '-6', '-19', '-14');
        });

        document.getElementById('CollapseEssentialServices').addEventListener('mouseover', (event) => {
            IDmouseover('EssentialServices', '', '-333', '-9');
        });
        document.getElementById('CollapseEssentialServices').addEventListener('mouseout', (event) => {
            IDmouseout('EssentialServices', '', '-333', '-9');
        });
        document.getElementById('ConflictEssentialServices').addEventListener('mouseover', (event) => {
            IDmouseover('EssentialServices', '', '-333', '-9');
        });
        document.getElementById('ConflictEssentialServices').addEventListener('mouseout', (event) => {
            IDmouseout('EssentialServices', '', '-333', '-9');
        });
        document.getElementById('PeacefulEssentialServices').addEventListener('mouseover', (event) => {
            IDmouseover('EssentialServices', '', '-333', '-9');
        });
        document.getElementById('PeacefulEssentialServices').addEventListener('mouseout', (event) => {
            IDmouseout('EssentialServices', '', '-333', '-9');
        });

        document.getElementById('CollapseOffensiveOperations').addEventListener('mouseover', (event) => {
            IDmouseover('OffensiveOperations', 'skip', '-21', '-10');
        });
        document.getElementById('CollapseOffensiveOperations').addEventListener('mouseout', (event) => {
            IDmouseout('OffensiveOperations', 'skip', '-21', '-10');
        });
        document.getElementById('ConflictOffensiveOperations').addEventListener('mouseover', (event) => {
            IDmouseover('OffensiveOperations', 'skip', '-21', '-10');
        });
        document.getElementById('ConflictOffensiveOperations').addEventListener('mouseout', (event) => {
            IDmouseout('OffensiveOperations', 'skip', '-21', '-10');
        });
    }
}

function addDataAIPolicyFunnel(id) {
    let html = '';
    if (id === 'International_Environment') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>International Environment</h5><p>While not a central driver of China’s AI governance measures, the geopolitical environment remains a significant factor in policy discussions. Chinese leaders’ persistent sense of being technologically encircled by hostile powers fuels demand for indigenous innovation and is used as a counterweight to push back against overly strict regulation.</p>";
    } else if (id === 'Public_Opinion') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Public opinion</h5><p>Public outcry over social issues can, in some cases, provoke a regulatory response from the government. Backlash against the exploitation of food delivery workers by algorithm-driven apps led to the immediate reprimanding of the companies by the government and, eventually, the inclusion of these algorithms in measures governing recommendation algorithms.</p>";
    } else if (id === 'Domestic_Political_Environment') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Domestic political environment</h5><p>The shape and stability of China’s domestic political environment is a major influence on Chinese technology policy. Some considerations here include the current effectiveness of information controls, proximity to major political events or anniversaries, and the balance of power between corporate actors and regulators, among many others.</p>";
    } else if (id === 'Economy_and_Labor') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Economy & labor</h5><p>A growing economy and healthy labor markets are core to Chinese social stability, making them major, though indirect, factors in AI regulation. Economic and labor conditions that gave rise to Xi’s common prosperity agenda and antimonopoly actions have likely contributed to provisions on recommendation algorithms covering antitrust and worker rights.</p>";
    } else if (id === 'AI_Capabilities') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>AI capabilities & business models</h5><p>The emergence of new AI capabilities, such as creating highly realistic deepfakes, can lead to preemptive regulation by the government before a major problem arises. In other cases, regulation follows only after a new business model has popularized the capability, such as with algorithm-driven news feeds and AI chatbots.</p>";
    } else if (id === 'Xi_Jinping_00000054949704223553830290000018052076726111144381_') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Xi Jinping</h5><p>As China’s most powerful leader in decades, Xi helps set the general direction of travel in AI policy and provides the ultimate backstop for major policy decisions. Despite Xi’s reputation for micromanaging certain policy areas, he does not appear to have done this with Chinese AI regulations thus far.</p>";
    } else if (id === 'CCP_Ideology') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>CCP Ideology</h5><p>CCP ideology acts as a political and intellectual filter through which Chinese policymakers interpret real-world issues. Philosophical concepts like Marxist dialectical materialism are instilled in bureaucrats through study sessions, while the more concrete ideological contention that media should be the “tongue and throat” of the party directly shapes regulatory policy.</p>";
    } else if (id === 'Internaional_AI_Debates') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>International AI governance debates</h5><p>China’s AI policy community extensively analyzes and draws lessons from international regulatory approaches, particularly in the EU. While Chinese regulations are never copies of those abroad, they often adapt foreign regulatory concepts to China’s context. Analysts also sometimes use commentary on issues abroad to indirectly comment on issues at home.</p>";
    } else if (id === 'Companies') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Companies</h5><p>Technology companies act as both technical advisers and active lobbyists on AI regulation. Representatives from Alibaba, Baidu, and Tencent sit on AI policy advisory committees, and the companies lobby policymakers around new regulations. Tencent Research Institute, Tencent’s in-house think tank, has been a notable public-facing thought leader on AI policy.</p>";
    } else if (id === 'Media') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Media</h5><p>Chinese media can act as a signal of government priorities, forum for policy debate, or venue to highlight issues. Below are select publications playing each role.</p><p>Signal: People’s Daily (人民日报); Guangming Daily (光明日报)</p><p>Debate: People’s Post and Telegraph (人民邮电报); Science & Technology Daily (科技日报); AI-focused WeChat public accounts</p><p>Highlight: People (人物)</p>";
    } else if (id === 'Academics_x2C__think_tanks') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Think tanks, academics & gov advisors</h5><p>Much public debate on AI governance occurs in publications and workshops featuring academics, researchers, and government advisers. Below are select organizations and individuals shaping these debates.</p><p>Think tanks: CAICT; CASTED; Tsinghua I-AIIG</p><p>Academics: Zhang Linghan (张凌寒); Liang Zheng (梁正); Xue Lan (薛澜); Zeng Yi (曾毅)</p><p>Advisers: National New Generation AI Governance Expert Committee</p>";
    } else if (id === 'Other_Orgs') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Other ministries and orgs</h5><p>Though the Central Committee, the CAC, and the MOST have led the charge on AI governance thus far, several ministries and administrative bodies have contributed to and co-signed the final regulations. MIIT and MPS have featured most prominently, but smaller bodies like SAMR likely shaped provisions on monopolistic behavior and worker rights.</p>";
    } else if (id === 'Draft_Regulation') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Draft Regulation</h5><p>Most Chinese AI governance measures are first released in draft form, allowing for a period of public comment before a revised version becomes final. In the interim, new bureaucratic organizations can also co-sign the regulation. Changes between draft and final regulations provide one window into bureaucratic tussles and policy debates.</p>";
    } else if (id === 'MOST') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>MOST</h5><p>The MOST has played a prominent role in creating AI governance principles, but not in regulations targeting specific applications. Its expert committees issued AI ethics and governance principles, and its draft technology ethics policy was adopted by the Central Committee. But it hasn’t co-signed regulations on recommendation algorithms or deep synthesis.</p>";
    } else if (id === 'CAC') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>CAC</h5><p>The CAC has emerged as the key regulator for AI and algorithms to date. It has solo-authored drafts of three major regulations, adding other ministries as co-signatories for the final regulations. When drafting regulations, CAC often utilizes experts affiliated with other ministries, making it a central hub and coordinator within the bureaucracy.</p>";
    } else if (id === 'State_Council') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>State Council & NPC</h5><p>The State Council, the government’s highest administrative body, is responsible for implementing CCP policy decisions. It issued the landmark 2017 AI development plan and has now pledged to begin drafting a national AI law. That draft will eventually be submitted to the National People’s Congress (NPC) for revisions and approval.</p>";
    } else if (id === 'Central_Committe') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>CCP Central Committee</h5><p>The most powerful CCP body short of the Politburo, the Central Committee has set the direction for Chinese AI governance. A 2020 committee document targeted recommendation algorithms and deepfakes for regulation, leading directly to two future regulations. It also issued a major policy document on technology ethics and governance.</p>";
    } else if (id === 'Economy_and_Labor-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Economy & labor</h5><p>A growing economy and healthy labor markets are core to Chinese social stability, making them major, though indirect, factors in AI regulation. The economic and labor conditions that gave rise to Xi’s common prosperity agenda and antimonopoly actions have likely contributed to provisions on recommendation algorithms covering antitrust and worker rights. This year’s economic downturn also contributed to the loosening of requirements in the 2023 generative AI regulation.</p>";
    } else if (id === 'International_Environment-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>International environment</h5><p>While not a central driver of China’s early AI governance measures, international competitiveness has become a more significant factor shaping recent AI regulations. Concerns that China was behind in generative AI led policymakers to dial down certain requirements in the 2023 generative AI regulation for fear that overly strict regulation would hold Chinese AI development back.</p>";
    } else if (id === 'Academics_x2C__think_tanks-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>Think tanks</h5><p>Much of the public debate on AI governance occurs in publications and workshops featuring academics, researchers, and government advisers. Below are select organizations and individuals who have shaped those debates.<br /><br />Think tanks: CAICT, Tsinghua I-AIIG, CAS, CASS<br /><br />Academics: Zhang Linghan (张凌寒), Xu Ke (许可), Xue Lan (薛澜), Sun Ping (孙萍), Zeng Yi (曾毅)<br /><br />Advisers: National New Generation Artificial Intelligence Governance Specialist Committee</p>";
    } else if (id === 'Internaional_AI_Debates_New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>International AI debates</h5><p>China’s AI policy community extensively analyzes and draws lessons from international regulatory approaches, particularly in the EU. While Chinese regulations are never copies of those abroad, they often adapt foreign regulatory concepts to China’s context. Analysts also sometimes use commentary on issues abroad to indirectly comment on issues at home.</p>";
    } else if (id === 'Central_Committe-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>CCP Central Committee</h5><p>The CCP Central Committee is the most powerful party body to weigh in directly on AI governance. A 2020 committee document targeted recommendation algorithms and deepfakes for regulation, leading directly to two future regulations. It also issued a major policy document on technology ethics and governance.</p>";
    } else if (id === 'State_Council-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>State Council & NPC</h5><p>The State Council, the government’s highest administrative body, is responsible for implementing CCP policy decisions. It issued the landmark 2017 AI development plan and in 2023 pledged to begin working toward a national AI law. The future of that law is uncertain, but if it moves forward, it will progress through the National People’s Congress (NPC) for revisions and approval.</p>";
    } else if (id === 'MOST-New') {
        html =
            "<div class='close-panel' id='closeInfoAIFunnel'><svg data-v-459a476e='' xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' class=''><circle cx='18' cy='18' r='17.5' stroke='currentColor'></circle><path stroke='currentColor' d='M25.07 10.93 10.93 25.07M25.07 25.07 10.93 10.93'></path></svg></div><h5>MOST</h5><p>The Ministry of Science and Technology (MOST) has played a prominent role in creating AI governance principles but not in regulating specific applications. Its expert committees issued AI ethics and governance principles, and its draft technology ethics policy was adopted by the Central Committee. The MOST did not co-sign the regulations on recommendation algorithms or deep synthesis, but it did co-sign the 2023 regulation on generative AI.</p>";
    }
    if (html.length) {
        document.querySelector('#data').innerHTML = html;
    }
}
function closeAIPolicyFunnel(activeThing) {
    document.getElementById('closeInfoAIFunnel').addEventListener('click', (event) => {
        activeThing.closest('svg').classList.remove('active');
        activeThing.closest('svg').classList.add('default');
        document.querySelector('#data').innerHTML = 'Click on the sections above to learn more about them.';
    });
}
function displayAIPolicyFunnel(e) {
    console.log(e.target.closest('.svg-click'));
    const activeThing = e.target.closest('.svg-click');
    if (activeThing.classList.contains('active')) {
        activeThing.classList.remove('active');
        activeThing.closest('svg').classList.remove('active');
        activeThing.closest('svg').classList.add('default');
        document.querySelector('#data').innerHTML = 'Click on the sections above to learn more about them.';
    } else {
        const allFunnels = document.querySelectorAll('.svg-click');
        const allHeadings = document.querySelectorAll('.svg-header');
        allFunnels.forEach((el) => {
            if (el.classList.contains('active')) {
                el.classList.remove('active');
            }
        });
        allHeadings.forEach((el) => {
            if (el.classList.contains('active')) {
                el.classList.remove('active');
            }
        });
        activeThing.classList.add('active');
        activeThing.closest('svg').classList.add('active');
        const activeThingSiblingsNotText = Array.from(activeThing.parentNode.childNodes).filter(
            (node) => node.nodeType === 1,
        );
        activeThingSiblingsNotText.forEach((el) => {
            if (el.id !== activeThing.id && el.classList.contains('active')) {
                el.classList.remove('active');
            }
            if (el.classList.contains('svg-header')) {
                el.classList.add('active');
            }
        });
        addDataAIPolicyFunnel(activeThing.id);
        closeAIPolicyFunnel(activeThing);
    }
}

onMounted(() => {
    const accordion = document.querySelectorAll('.accordion-trigger');
    accordion.forEach((el) =>
        el.addEventListener('click', (e) => {
            if (e.target.className.includes('active')) {
                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            } else {
                e.target.classList.toggle('active');
                e.target.nextElementSibling.classList.toggle('active');
            }
        }),
    );
    const overlay = document.querySelectorAll('.overlay-trigger');
    overlay.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            revealOverlay(e.target.hash);
        }),
    );
    const nextSlideSlideShow = document.querySelectorAll('.next-button');
    nextSlideSlideShow.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            nextSlide(e.target);
        }),
    );
    const prevSlideSlideshow = document.querySelectorAll('.prev-button');
    prevSlideSlideshow.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            prevSlide(e.target);
        }),
    );
    const moreLessTrigger = document.querySelectorAll('.more-less-trigger');
    moreLessTrigger.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            moreLessShow(e);
        }),
    );
    const showMore = document.querySelectorAll('.showMore');
    showMore.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.target.classList.add('hidden');
            e.target.nextElementSibling.classList.add('active');
        }),
    );
    const showLess = document.querySelectorAll('.showLess');
    showLess.forEach((el) =>
        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.target.parentElement.previousElementSibling.classList.remove('hidden');
            e.target.parentElement.classList.remove('active');
        }),
    );
    const quadSpaceBilatsSVG = document.querySelectorAll('#quad-buttons .svg-click');
    quadSpaceBilatsSVG.forEach((el) =>
        el.addEventListener('click', (e) => {
            quadSpaceBilats(e);
        }),
    );
    const AIPolicyFunnelSVGClick = document.querySelectorAll('#AIPolicyFunnel .svg-click');
    AIPolicyFunnelSVGClick.forEach((el) =>
        el.addEventListener('click', (e) => {
            displayAIPolicyFunnel(e);
        }),
    );
    diversitySelect();
    IndiaStateImageSwap2018();
    EUMapsImageSwap2020();
    makeYouTubeResponsive();
    KoreanUnification2019();
    MoonNorthKorea2019();
});
</script>

<style lang="scss" scoped>
//- support legacy accordions
:deep(.accordion-trigger) {
    margin-bottom: 2rem;

    &:after {
        content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" stroke="currentColor" class="arrow" data-v-31afad4f=""><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.791.833 5 4.625 1.208.833"></path></svg>');
        display: inline-block;
        height: 1rem;
        margin-left: 1rem;
        width: 1rem;
    }
    &.blue-background:after {
        content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" stroke="white" class="arrow" data-v-31afad4f=""><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.791.833 5 4.625 1.208.833"></path></svg>');
    }

    &.active:after {
        transform: rotate(180deg);
        vertical-align: bottom;
    }

    .down-arrow {
        display: none;
    }
}

:deep(.accordion-content) {
    display: none;

    &.active {
        display: block;
        margin-top: 3rem;
    }
}

//-supporting legacy text box styles
:deep(.text-box) {
    background-color: #e6e7e8;
    display: inline-block;
    margin-top: 0;
    margin-bottom: var(--paragraph-bottom-margin);
    padding: 3rem;
    width: 100%;

    h3,
    h4,
    h5 {
        margin-top: 0;
    }

    h5 {
        color: #000000;
        text-transform: uppercase;
    }

    strong {
        color: #929497;
        font-size: 1.3em;
        padding: 0 3rem 0 0;
    }

    .section {
        margin-top: 0;
    }
}

:deep(.gray-background) {
    background-color: #e6e7e8;
    display: inline-block;
    margin-top: 0;
    padding: 3rem;
    width: 100%;

    h5 {
        color: #000000;
        text-transform: uppercase;
    }

    strong {
        color: #929497;
        font-size: 1.3em;
        padding: 0 3rem 0 0;
    }

    .section {
        margin-top: 0;
    }
}

:deep(.photo-caption) {
    font-style: italic;
    font-size: 0.8em;
}

//-supporting legacy button styles
:deep(.button) {
    gap: 0.8rem;
    color: rgba(var(--color-rgb-blanc-default), 0.8);
    background-color: rgb(var(--color-rgb-teal-default));
    border-radius: 5px;
    padding: 1rem 2rem 1.2rem;
    margin: 2rem 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

:deep(.button-big) {
    gap: 0.8rem;
    color: rgba(var(--color-rgb-blanc-default), 0.8);
    background-color: rgb(var(--color-rgb-teal-default));
    border-radius: 5px;
    padding: 1.6rem 2.6rem 1.8rem;
    margin: 2rem 0;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

:deep(a.button-big),
:deep(a.button) {
    text-decoration: none;
}

// legacy long external quotes
:deep(.extQuote) {
    margin-left: 1em;
    margin-right: 1em;
}

//- adding styles for expected blockquotes
:deep(blockquote) {
    @include type-pullquote;
    padding-inline-start: 3.2rem;
    border-inline-start: 0.4rem solid var(--bar-color, color(teal));
    color: #004070;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}

// legacy clean list
:deep(ul.clean-list) {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin: 0;
    }
}
:deep(ul.list-across-spaced) {
    list-style: none;

    li {
        margin-right: 2em;
        display: inline-block;
    }
}

//-overlay
:deep(.ceip-overlay),
:deep(.overlay-content) {
    display: none;
}

:deep(.overlay-content) {
    position: fixed;
    background: lightgray;
    background: rgba(lightgray, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: none;

    &.active {
        display: block;
    }

    > div {
        background: white;
        border-radius: 7px;
        color: #000000;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 20%;
        width: 60rem;
        max-width: 60rem;
        max-height: 70vh;
        padding: 4rem 2rem;
        overflow: scroll;
        .overlay-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            svg {
                pointer-events: none;
            }
        }
        @include media-query(mobile) {
            max-width: 90vw;
        }
    }
}

//-carousel

:deep(.carousel.slide) {
    position: relative;
    .carousel-nav a {
        background: none repeat scroll 0 0 #fff;
        top: 40%;
        color: #ffffff;
        font-size: 3em;
        position: absolute;
        text-decoration: none;
        z-index: 9;
        &.next-button {
            right: 0;
            border-radius: 10px 0 0 10px;
            .icon-triangle-right {
                content: url(../../assets/svg/arrow-right.svg);
                height: 3rem;
                padding: 1rem;
                width: 3rem;
                pointer-events: none;
            }
        }
        &.prev-button {
            left: 0;
            border-radius: 0 10px 10px 0;
            .icon-triangle-left {
                content: url(../../assets/svg/arrow-left.svg);
                height: 3rem;
                padding: 1rem;
                width: 3rem;
                pointer-events: none;
            }
        }
    }
    .carousel-inner {
        .item {
            display: none;
            &.active {
                display: block;
            }
            .slide-content {
                color: #929497;
            }
        }
    }
    .carousel-indicators {
        display: none;
    }
}

//- reveals
:deep(.reveal-this.hidden) {
    display: none;
}
:deep(.moreToShow) {
    display: none;
    &.active {
        display: inline;
    }
}
:deep(.showMore.hidden) {
    display: none;
}
// hiding legacy author boxes
:deep(.author-box.no-print) {
    display: none;
}
:deep(p.hidden) {
    display: none;
}
:deep(table) {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: var(--paragraph-bottom-margin);
    td {
        border-bottom: (rgba(var(--color-rgb-noir-default), 0.1) solid 1px);
        padding: 1.5rem;
    }
    tr.row-background-highlight {
        background-color: rgba(var(--color-rgb-noir-default), 0.1);
    }
    thead td.table-title {
        border-bottom: rgba(var(--color-rgb-tan-default), 1) solid 1px;
        color: rgba(var(--color-rgb-tan-default), 1);
        font-size: 1.3em;
        padding-bottom: 0.5em;
        text-align: left;
        @include media-query(mobile) {
            font-size: 0.8em;
        }
    }
    tbody td,
    tfoot td {
        font-weight: 350;
        font-size: 0.9em;
        padding: 0.7em;
        p {
            font-size: 0.9em !important;
        }
        li {
            font-size: 0.9em !important;
        }
        @include media-query(mobile) {
            font-size: 0.8em;
            padding: 0.5em;
            p {
                font-size: 0.8em !important;
            }
        }
    }
}
// supporting old Strategic Europe
:deep(h4.author span.title) {
    border-left: 1px solid #000000;
    font-size: 0.8em;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
}
//column support without breaking on new columns
:deep(.cols) {
    clear: both;
    .col {
        float: left;

        @include media-query(mobile) {
            float: none;
            width: 100% !important;

            > .gutters {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .col-80 {
        width: 80%;
    }

    .col-70 {
        width: 70%;
    }

    //two across
    .col-50 {
        width: 50%;
    }

    .col-30 {
        width: 30%;
    }

    //four across
    .col-25 {
        width: 25%;
    }

    //five across
    .col-20 {
        width: 20%;
    }
}
:deep(.youtube-video) {
    @include media-query(mobile) {
        aspect-ratio: 16 / 9;
        height: auto;
        width: 100%;
    }
}
:deep(.center-desktop) {
    margin: 0 auto;
    width: 50%;
    @include media-query(phone) {
        width: 100%;
    }
}
// select style
:deep(select#demographicDEISwap) {
    border-radius: 4px;
    border: 2px solid rgb(var(--color-rgb-teal-default));
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    color: rgb(var(--color-rgb-teal-default));
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 30%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2300a79d%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: no-repeat, repeat;
    background-position:
        right 0.7em top 50%,
        0 0;
    background-size:
        0.65em auto,
        100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

//existing timeline support
:deep(.frst-container) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 15px 0 15px;

    &::after {
        /* clearfix */
        content: '';
        display: table;
        clear: both;
    }

    /* --------------------------------

    Main components

    -------------------------------- */
    .frst-timeline {
        position: relative;
    }

    .frst-right-align {
        text-align: right;
    }

    .frst-timeline-block {
        position: relative;
        min-height: 16px;
        padding-left: 35px;
        padding-right: 35px;
    }

    .frst-timeline-block::before {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }

    .frst-timeline-block:last-child::before {
        display: none;
    }

    .frst-left-align .frst-timeline-block::before {
    }

    .frst-right-align .frst-timeline-block::before {
        right: 0;
        left: auto !important;
        margin-left: 0 !important;
    }

    .frst-timeline-block::after {
        content: '';
        display: table;
        clear: both;
    }

    .frst-timeline-img {
        position: absolute;
        min-width: 1px;
        min-height: 1px;
        left: 0;
        top: 0;
    }

    .frst-timeline-img i {
        display: block;
    }

    .frst-right-align .frst-timeline-img {
        margin-left: 0 !important;
        left: auto !important;
        right: 0;
    }

    .frst-timeline-content-inner {
        position: relative;
    }

    .frst-date {
        display: block;
    }

    .frst-labels span {
        display: inline-block;
    }

    .frst-labels {
        padding: 20px 0;
    }

    .frst-labels.frst-start-label {
        padding-top: 0 !important;
    }

    .frst-labels.frst-end-label {
        margin-bottom: 0 !important;
    }

    @media only screen and (min-width: 679px) {
        .frst-date-opposite {
            margin-left: 190px;
        }

        .frst-left-align.frst-date-opposite {
            margin-left: 190px;
        }

        .frst-right-align.frst-date-opposite {
            margin-right: 190px;
            margin-left: 0;
            text-align: right;
        }

        .frst-date-opposite.frst-alternate {
            margin: 0;
        }

        .frst-timeline.frst-alternate.frst-left-align .frst-timeline-content,
        .frst-timeline.frst-date-opposite.frst-left-align .frst-timeline-content,
        .frst-timeline.frst-alternate.frst-right-align .frst-timeline-content,
        .frst-timeline.frst-date-opposite.frst-right-align .frst-timeline-content {
            width: 100%;
        }

        .frst-alternate .frst-timeline-block::before {
            left: 50%;
        }

        .frst-alternate .frst-timeline-img {
            left: 50% !important;
        }

        .frst-alternate .frst-timeline-block.frst-even-item .frst-timeline-content {
            float: right;
        }

        .frst-alternate .frst-timeline-block.frst-odd-item .frst-timeline-content {
            float: left;
        }

        .frst-date-opposite .frst-date {
            position: absolute;
            top: 0;
            text-align: right;
        }

        .frst-alternate .frst-timeline-block.frst-odd-item .frst-timeline-content,
        .frst-alternate.frst-date-opposite .frst-timeline-block.frst-even-item .frst-date,
        .frst-date-opposite.frst-left-align .frst-date,
        .frst-right-align {
            text-align: right;
        }

        .frst-alternate.frst-date-opposite .frst-timeline-block.frst-odd-item .frst-timeline-content .frst-date,
        .frst-date-opposite.frst-right-align .frst-date {
            text-align: left;
        }

        .frst-alternate .frst-timeline-label-block {
            text-align: center;
        }

        .frst-alternate .frst-timeline-label-block .frst-labels span {
            left: 0;
            right: 0;
        }
    }

    @media only screen and (max-width: 678px) {
        .frst-timeline {
            margin-left: 20px;
        }

        .frst-timeline.frst-right-align,
        .frst-timeline.frst-responsive-right {
            margin-right: 20px;
            margin-left: 0;
        }

        .frst-responsive-right .frst-timeline-block::before {
            right: 0;
            left: auto !important;
            margin-left: 0 !important;
        }

        .frst-responsive-right {
            text-align: right;
        }

        .frst-responsive-right .frst-timeline-img {
            margin-left: 0 !important;
            left: auto !important;
            right: 0;
        }

        .frst-date {
            margin-bottom: 10px;
        }
    }

    /*-------- Type- 01 ------*/

    .frst-timeline-style-8 {
        color: #000;
    }

    .frst-timeline-style-8 .frst-timeline-block::before {
        width: 3px;
        background: rgba(0, 0, 0, 0);
        margin-left: -2px;
        background: rgba(12, 12, 12, 0.8);
        top: 12px;
    }

    .frst-timeline-style-8.frst-right-align .frst-timeline-block::before {
        margin-right: -2px;
    }

    .frst-timeline-block {
        padding-bottom: 30px;
        padding-left: 45px;
        padding-right: 45px;
    }

    .frst-timeline-style-8 .frst-timeline-img {
        padding: 0;
        margin-left: -21px;
        top: 6px;
        /*--- Box shadow value --*/
        width: 42px;
        height: 42px;
        color: #fff;
        text-align: center;
        font-size: 24px;
    }

    .frst-timeline-style-8 .frst-timeline-img span {
        display: inline-block;
        margin: auto;
        padding: 6px;
        height: 50%;
        width: 50%;
        background: #202020;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        vertical-align: top;
        border: 3px solid #202020;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        vertical-align: middle;
    }

    .frst-timeline-style-8 .frst-timeline-img i {
        display: block;
    }

    .frst-timeline-style-8.frst-right-align .frst-timeline-img {
        margin-right: -21px;
    }

    .frst-timeline-style-8 .frst-timeline-content {
        color: #777;
        background: white;
    }

    .frst-timeline-style-8 .frst-date {
        color: #9c9c9c;
    }

    .frst-timeline-style-8 h2 {
        margin: 0 0 5px;
        font-weight: 800;
        color: #444;
        font-size: 18px;
    }

    .frst-date-opposite.frst-timeline-style-8 h2 {
        font-family: 'Frutiger Neue LT W06 Book', Arial, sans-serif;
        margin-top: 1em;
    }

    .frst-timeline-style-8 p {
        margin: 0;
        font-family: 'Frutiger Neue LT W06 Thin', Arial, sans-serif;
        font-size: 0.75em;
        line-height: normal;
    }

    .frst-timeline-style-8 .frst-timeline-label-block {
        padding-top: 0;
    }

    .frst-timeline-style-8 .frst-labels span {
        font-family: 'Frutiger Neue LT W06 Book', Arial, sans-serif;
        position: relative;
        background: #202020;
        color: #fff;
        font-weight: 800;
        padding: 8px 30px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
    }

    .frst-timeline-style-8.frst-right-align .frst-labels span {
        left: auto;
        right: -47px;
    }

    .frst-timeline-style-8.frst-left-align .frst-labels span {
        right: auto;
        left: -47px;
    }

    /*--------- news-------*/

    .frst-timeline-style-8 .frst-timeline-content-inner {
        border: 2px solid #d8d8d8;
        padding: 30px;
        position: relative;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    .frst-timeline-style-8 .frst-timeline-content-inner .media-section {
        width: 100%;
        max-height: 350px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .frst-timeline-style-8 .frst-timeline-content-inner .media-section iframe {
        max-width: 100%;
    }

    .frst-timeline-style-8 .frst-timeline-content-inner::before,
    .frst-timeline-style-8 .frst-timeline-content-inner::after {
        content: '';
        border-style: solid;
        border-color: transparent;
        position: absolute;
    }

    .frst-timeline-style-8 .frst-timeline-content-inner::before {
        border-width: 10px 11px 10px 0;
        border-right-color: #d8d8d8;
        left: -11px;
        top: 10px;
    }

    .frst-timeline-style-8 .frst-timeline-content-inner::after {
        border-width: 8px 9px 8px 0;
        border-right-color: #fff;
        left: -9px;
        top: 12px;
    }

    .frst-timeline-style-8.frst-right-align .frst-timeline-content-inner::before {
        border-width: 10px 0 10px 11px;
        border-left-color: #d8d8d8;
        left: auto;
        right: -11px;
    }

    .frst-timeline-style-8.frst-right-align .frst-timeline-content-inner::after {
        border-width: 8px 0 8px 9px;
        border-left-color: #fff;
        right: -8px;
        left: auto;
    }

    /*---------- Responsive part ------------*/

    @media only screen and (min-width: 679px) {
        .frst-date-opposite {
            margin-left: 203px;
        }

        .frst-left-align.frst-date-opposite {
            margin-left: 203px;
        }

        .frst-right-align.frst-date-opposite {
            margin-right: 203px;
            margin-left: 0;
        }

        .frst-timeline-style-8.frst-alternate .frst-timeline-content,
        .frst-timeline-style-8.frst-date-opposite .frst-timeline-content {
            width: calc(50% - 44px);
        }

        .frst-timeline-style-8.frst-date-opposite .frst-date {
            font-family: 'Frutiger Neue LT W06 Book', Arial, sans-serif;
            left: -248px;
            line-height: 32px;
            width: 160px;
        }

        .frst-timeline-style-8.frst-date-opposite.frst-right-align .frst-date,
        .frst-timeline-style-8.frst-date-opposite.frst-alternate .frst-timeline-block.frst-odd-item .frst-date {
            right: -248px;
            left: auto;
            text-align: left;
        }

        .frst-timeline-style-8.frst-right-align .frst-date {
            left: auto;
            right: 0;
        }

        /*--------- news-------*/
        .frst-timeline-style-8.frst-alternate .frst-odd-item .frst-timeline-content-inner::before {
            border-width: 10px 0 10px 11px;
            border-left-color: #d8d8d8;
            left: auto;
            right: -11px;
        }

        .frst-timeline-style-8.frst-alternate .frst-odd-item .frst-timeline-content-inner::after {
            border-width: 8px 0 8px 9px;
            border-left-color: #fff;
            right: -8px;
            left: auto;
        }
    }

    @media only screen and (max-width: 678px) {
        .frst-timeline-style-8.frst-responsive-right .frst-labels span {
            left: auto;
            right: -47px;
        }

        .frst-timeline-style-8.frst-responsive-right .frst-timeline-block::before {
            margin-right: -2px;
        }

        .frst-timeline-style-8.frst-responsive-right .frst-timeline-img {
            margin-right: -21px;
        }

        /*--------- news-------*/
        .frst-timeline-style-8.frst-responsive-right .frst-timeline-content-inner::before {
            border-width: 10px 0 10px 11px;
            border-left-color: #d8d8d8;
            left: auto;
            right: -11px;
        }

        .frst-timeline-style-8.frst-responsive-right .frst-timeline-content-inner::after {
            border-width: 8px 0 8px 9px;
            border-left-color: #fff;
            right: -8px;
            left: auto;
        }
    }

    .frst-alternate .frst-timeline-block.frst-odd-item .frst-timeline-content,
    .frst-date-opposite.frst-left-align .frst-date,
    .frst-right-align {
        text-align: left;
    }

    .frst-alternate.frst-date-opposite {
        .frst-timeline-block.frst-odd-item .frst-date {
            line-height: 2em;
            text-align: left;
        }

        .frst-timeline-block.frst-even-item .frst-date {
            line-height: 2em;
            text-align: right;
        }
    }

    .frst-timeline-style-8 .frst-timeline-content-inner {
        padding: 1em 1em 0 1em;

        h2 {
            @include type-h3;
            margin-top: 0;
        }

        p {
            @include type-body;
            margin-bottom: 15px;
        }
    }

    .frst-timeline-label-block {
        padding-left: 0;

        .frst-labels {
            margin-left: -50px;
            @include type-body;
            @include font-weight(bold);

            span {
                background-color: #0050a9;
                font-size: 2rem;
            }
        }
    }

    .frst-timeline-style-8 .frst-timeline-img span {
        background-color: #0050a9;
        border: #0050a9 solid 3px;
    }

    .frst-timeline-style-8 .frst-timeline-block::before {
        background: rgba(0, 80, 169, 0.8) none repeat scroll 0 0;
    }

    .frst-date {
        color: #005089;
        @include type-body;
        font-size: 1.1em;
        line-height: 2em;
        text-transform: uppercase;
    }

    .frst-timeline-style-8 li {
        margin: 0;
        @include type-body;
        font-size: 1rem;
        line-height: normal;
    }

    .frst-timeline-style-8 p {
        color: #6e6e71;
        font-size: 1rem;
        @include type-body;
    }

    .frst-timeline-style-8 h4 {
        @include type-h4;
        color: #000000;
        font-size: 1.5rem;
        margin-top: 2em;
        text-transform: uppercase;
    }
    @include media-query(tablet) {
        .frst-timeline-label-block .frst-labels {
            margin-left: -15px;
        }
    }

    @include media-query(phone) {
        .frst-timeline-label-block .frst-labels {
            margin-left: -30px;
        }

        .frst-timeline-block {
            padding-left: 25px;
            padding-right: 0;
        }
    }
}
// space quad svg
:deep(#quadSpaceBilats) {
    .roman-normal-book {
        font-size: 0.9em;
    }
    h5 {
        text-transform: uppercase;
        margin-top: 30px;
    }
    .double-border {
        border-top: 1px #000000 solid;
        border-bottom: 1px solid #000000;
        margin: 15px 0 45px 0;
        padding: 15px 0;
    }
    #quad-buttons {
        max-width: 600px;
        margin: 0 auto 30px auto;
        .g {
            fill: #8f9498;
        }
        .active .g {
            fill: #00a79d;
        }
        .h {
            fill: #fff;
            font-family: Whitney-Bold, Whitney;
            font-size: 39.09px;
            cursor: default;
        }
    }
    #accords {
        .gutters {
            margin-left: 30px;
        }
        h4 {
            color: #003a63;
            padding-top: 0;
        }
    }
    .bilats {
        font-size: 0.9em;
        &.inactive {
            display: none;
            color: #000000;
            cursor: auto;
        }
        &.active {
            display: block;
        }
        img {
            margin-bottom: 30px;
            max-height: 100px;
        }
    }
}
//styles for the Korean Unification SVG
:deep(.doc78737) {
    svg {
        &.rollover .cls-0,
        &.rollover .cls-1,
        &.rollover .cls-2,
        &.rollover .cls-3,
        &.rollover .cls-4,
        &.rollover .cls-5,
        &.rollover .cls-6,
        &.rollover .cls-7,
        &.rollover .cls-23,
        &.rollover .cls-24 {
            fill: #e5e5e5;
            stroke: #929497;
        }
        &.rollover {
            *[data-name='text-description'] path,
            #text-description path,
            #text-description-222 path,
            #text-description-333 path {
                fill: #000000;
            }
        }

        .cls-1.active {
            fill: #d0d8df;
        }
        .cls-2.active {
            fill: #4e6b88;
        }
        .cls-3.active {
            fill: #00a79d;
        }
        .cls-4.active {
            fill: #002548;
        }
        .cls-5.active {
            fill: #fbb043;
        }
        .cls-6.active {
            fill: #006098;
        }
        .cls-7.active {
            fill: #ef4136;
        }
        .cls-24.active {
            fill: #7e8183;
        }
        *[data-name='text-description'].active path,
        #text-description.active path,
        #text-description-222.active path,
        #text-description-333.active path {
            fill: #ffffff;
        }
    }

    .rok-dark-blue {
        background-color: #9bc3e7;
    }
    .rok-light-blue {
        background-color: #d7e8f7;
    }
    .rok-white {
        background-color: #ffffff;
    }

    .table-title {
        text-transform: uppercase;

        strong {
            text-transform: none;
        }
    }

    .zone-1 table td {
        line-height: 1.4rem;
    }

    .key {
        display: block;
        clear: both;
        margin-bottom: 30px;
        text-transform: none;

        > div {
            display: inline-block;
            float: right;
            margin-left: 15px;

            .sample {
                border: 1px solid #929497;
                display: inline-block;
                height: 15px;
                margin-right: 5px;
                width: 15px;
            }
        }
    }

    .small-text {
        line-height: 1rem;
    }

    .accordion-trigger {
        color: #000000;
        background-color: #e6e7e8;
        padding: 1.5rem;
        text-transform: none;

        &::after {
            content: none;
        }
        &::before {
            border: 1px solid #000000;
            border-radius: 50%;
            content: '+';
            display: inline;
            font-size: 15px;
            line-height: 15px;
            margin-right: 15px;
            padding: 5px 8px;
            position: relative;
        }

        &.active::before {
            content: '-';
            padding: 5px 9px;
        }
    }
    .small-text {
        font-size: 0.79em;
        margin-bottom: 2rem;
    }

    .accordion-content {
        td:not(:first-child) {
            border-left: 1px solid #929497;
        }
        td:not(:last-child) {
            border-right: 1px solid #929497;
        }
        tr:not(:first-child) td {
            border-top: 1px solid #929497;
            border-bottom: none;
        }
        tr:first-child td {
            border-bottom: 1px solid #929497;
        }
    }
}
// Sheehan China AI SVG
:deep(#AIPolicyFunnel) {
    #data {
        padding: 1em;
    }
    #AIPolicyData.active .box {
        border: 1px solid #000000;
        margin: 30px 0;
        h5 {
            color: #003a63;
            font-size: 1.3em;
        }
        p {
            font-size: 1.5rem;
            line-height: 1.6em;
        }
        .close-panel {
            color: #003a63;
            float: right;
            font-size: 1.3em;
            margin-top: -1.5em;
            padding-right: 1em;
        }
    }
    svg {
        padding-top: 30px;
        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #5bd0f4;
        }
        .st1 {
            fill: #ffffff;
        }
        .st2 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #27aae1;
        }
        .st3 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #1c75bc;
        }
        .st4 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #005588;
        }
        .st5 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #003a63;
        }
        .st6 {
            fill: #e6e7e8;
        }
        .st7 {
            fill: none;
            stroke: #003a63;
            stroke-width: 6.7759;
            stroke-miterlimit: 10;
        }
        .st8 {
            fill: #003a63;
        }
        .st9 {
            fill: none;
            stroke: #ef4131;
            stroke-width: 2.7104;
            stroke-miterlimit: 10;
        }
        .st10 {
            fill: #ef4131;
        }
        .st11 {
            fill: none;
            stroke: #ef4131;
            stroke-width: 1.3552;
            stroke-miterlimit: 10;
        }
        .st12 {
            fill: none;
            stroke: #ef4131;
            stroke-width: 6.7759;
            stroke-miterlimit: 10;
        }
        .st13 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #ef4131;
        }
        .st14 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #bc3124;
        }
        .st15 {
            fill: #00a79d;
        }
        .st16 {
            fill: none;
            stroke: #00a79d;
            stroke-width: 6.7759;
            stroke-miterlimit: 10;
        }
        .st17 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #3addcd;
        }
        .st18 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #00a79d;
        }
        .st19 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #00867e;
        }
        .st20 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #00645e;
        }
        .st21 {
            fill: #fbb040;
        }
        .st22 {
            fill: none;
            stroke: #fbb040;
            stroke-width: 6.7759;
            stroke-miterlimit: 10;
        }
        .st23 {
            fill: #f7941d;
        }
        .st24 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #f7c077;
        }
        .st25 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #fbb040;
        }
        .st26 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #f7941d;
        }
        .st27 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #e0881c;
        }
    }
    svg.active {
        .st0 {
            opacity: 0.4;
        }
        .st1 {
            fill: #ffffff;
        }
        .st2 {
            opacity: 0.4;
        }
        .st3 {
            opacity: 0.4;
        }
        .st4 {
            opacity: 0.4;
        }
        .st5 {
            opacity: 0.4;
        }
        .st6 {
            opacity: 0.4;
        }
        .st7 {
            opacity: 0.4;
        }
        .st8 {
            opacity: 0.4;
        }
        .st9 {
            opacity: 0.4;
        }
        .st10 {
            opacity: 0.4;
        }
        .st11 {
            opacity: 0.4;
        }
        .st12 {
            opacity: 0.4;
        }
        .st13 {
            opacity: 0.4;
        }
        .st14 {
            opacity: 0.4;
        }
        .st15 {
            opacity: 0.4;
        }
        .st16 {
            opacity: 0.4;
        }
        .st17 {
            opacity: 0.4;
        }
        .st18 {
            opacity: 0.4;
        }
        .st19 {
            opacity: 0.4;
        }
        .st20 {
            opacity: 0.4;
        }
        .st21 {
            opacity: 0.4;
        }
        .st22 {
            opacity: 0.4;
        }
        .st23 {
            opacity: 0.4;
        }
        .st24 {
            opacity: 0.4;
        }
        .st25 {
            opacity: 0.4;
        }
        .st26 {
            opacity: 0.4;
        }
        .st27 {
            opacity: 0.4;
        }
        .svg-click.active,
        .svg-header.active {
            .st0 {
                opacity: 1;
            }
            .st1 {
                fill: #ffffff;
            }
            .st2 {
                opacity: 1;
            }
            .st3 {
                opacity: 1;
            }
            .st4 {
                opacity: 1;
            }
            .st5 {
                opacity: 1;
            }
            .st6 {
                opacity: 1;
            }
            .st7 {
                opacity: 1;
            }
            .st8 {
                opacity: 1;
            }
            .st9 {
                opacity: 1;
            }
            .st10 {
                opacity: 1;
            }
            .st11 {
                opacity: 1;
            }
            .st12 {
                opacity: 1;
            }
            .st13 {
                opacity: 1;
            }
            .st14 {
                opacity: 1;
            }
            .st15 {
                opacity: 1;
            }
            .st16 {
                opacity: 1;
            }
            .st17 {
                opacity: 1;
            }
            .st18 {
                opacity: 1;
            }
            .st19 {
                opacity: 1;
            }
            .st20 {
                opacity: 1;
            }
            .st21 {
                opacity: 1;
            }
            .st22 {
                opacity: 1;
            }
            .st23 {
                opacity: 1;
            }
            .st24 {
                opacity: 1;
            }
            .st25 {
                opacity: 1;
            }
            .st26 {
                opacity: 1;
            }
            .st27 {
                opacity: 1;
            }
        }
    }
}
:deep(#IndiaStateImageSwap-doc77406) {
    select {
        border: 1px solid #000000;
        margin: 1em auto;
        padding: 0.5em 1em;
        width: 50%;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }
}
:deep(#EUMaps-doc81658) {
    label {
        display: block;
    }
    select {
        border: 1px solid #000000;
        margin: 1em auto;
        padding: 0.5em 1em;
        width: 70%;
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }
}

// styles for the Moon QA interactive
:deep(.MoonNorthKorea-doc77946) {
    li {
        font-size: 0.7em;
        line-height: 1.2em;

        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }
}

:deep(.doc72884) {
    .affirm {
        color: #4aca2a;
    }
    .contemplate {
        color: #fbe006;
    }
    .deny {
        color: #fb9d06;
    }
    .reject {
        color: #ff0000;
    }
    .neutral {
        color: #949494;
    }
    .icon-triangle-right {
        width: 0;
        height: 0;
        display: inline-block;
        margin: 1em 0.5em 0 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #000000;

        &.affirm {
            border-left: 10px solid #4aca2a;
        }
        &.contemplate {
            border-left: 10px solid #fbe006;
        }
        &.deny {
            border-left: 10px solid #fb9d06;
        }
        &.reject {
            border-left: 10px solid #ff0000;
        }
        &.neutral {
            border-left: 10px solid #949494;
        }
    }
}
</style>
